// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Theme$Weblab from "../service/Theme.bs.js";
import * as ReactHelmet from "react-helmet";
import * as Core from "@material-ui/core";
import * as Styles from "@material-ui/core/styles";
import Cancel from "@material-ui/icons/Cancel";
import Weblab_logoSvg from "/src/assets/svg/weblab_logo.svg";

var theme = Styles.createTheme(Theme$Weblab.getThemeProto(false));

function PaymentCancelled(Props) {
  return React.createElement(Styles.ThemeProvider, {
              children: null,
              theme: theme
            }, React.createElement(ReactHelmet.Helmet, {
                  children: null
                }, React.createElement("link", {
                      href: "/favicon.png",
                      rel: "icon",
                      type: "image/png"
                    }), React.createElement("title", undefined, "Weblab Payment Successful")), React.createElement(Core.Box, {
                  style: {
                    bottom: "0",
                    left: "0",
                    position: "absolute",
                    right: "0",
                    top: "0"
                  },
                  children: null,
                  height: "40%",
                  margin: "auto",
                  width: "40%"
                }, React.createElement(Core.Typography, {
                      children: null,
                      color: "primary",
                      variant: "h3",
                      style: {
                        fontWeight: "700",
                        marginBottom: "32px"
                      }
                    }, React.createElement(Core.Box, {
                          clone: true,
                          children: React.createElement(Weblab_logoSvg, {}),
                          height: 64,
                          width: 64
                        }), "Web", React.createElement(Core.Box, {
                          style: {
                            color: theme.palette.secondary.main
                          },
                          children: "lab",
                          display: "inline"
                        })), React.createElement(Core.Box, {
                      children: null,
                      alignItems: "center",
                      display: "flex"
                    }, React.createElement(Cancel, {
                          color: "error",
                          fontSize: "large"
                        }), React.createElement(Core.Typography, {
                          children: "Your payment was cancelled. You have not been charged. ",
                          variant: "body1"
                        }))));
}

PaymentCancelled.displayName = "PaymentCancelled";

var make = PaymentCancelled;

var $$default = PaymentCancelled;

export {
  theme ,
  make ,
  $$default ,
  $$default as default,
  
}
/* theme Not a pure module */
